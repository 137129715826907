import { graphql } from "gatsby"
import * as React from "react"
import { Heading } from "../components/01_atoms/Heading/Heading"
import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph"
import HeroAnimation from "../components/03_organisms/animations/HeroAnimation/HeroAnimation"
import Carousel from "../components/03_organisms/Carousel/Carousel"

import ImageTextBlock from "../components/03_organisms/ImageTextBlock/ImageTextBlock"
import { RenderComponent } from "../components/components"
import Layout from "../components/layout"
import Seo from "../components/seo"

import * as styles from "../components/components.module.scss"
import TimeLineItem from "../components/03_organisms/TimelineItem/TimelineItem"

const IndexPage = ({ data }) => {
  const pageData = data.allNodeAboutUs.nodes[0]
  const components = pageData.relationships?.field_components

  return (
    <Layout>
      <Seo
        title={pageData.field_meta_title}
        description={pageData.field_meta_description}
      />

      {pageData && (
        <>
          {/* Hero Block */}
          <ImageTextBlock
            height="100"
            className="hero"
            image_position="left"
            background_color="Azure"
            animation={
              <HeroAnimation
                image={<img src="/img/heroes/different.png" alt="Contact" />}
                position="left"
                background_color="Regent St Blue"
              />
            }
          >
            <Heading size="xxl" spacing="l">
              {pageData.title}
            </Heading>
            <Paragraph>
              <span
                dangerouslySetInnerHTML={{ __html: pageData.body.processed }}
              />
            </Paragraph>
          </ImageTextBlock>

          {/* Zigzag */}

          {components?.map((component, index) =>
            component.internal.type === "paragraph__carousel" ? (
              <Carousel key={index} title={component.field_title}>
                {component.relationships.field_items.map((item, index) => (
                  <TimeLineItem
                    title={item.field_title}
                    description={item.field_short_description}
                    image={item.relationships.field_image}
                  />
                ))}
              </Carousel>
            ) : (
              RenderComponent(component, index)
            )
          )}
        </>
      )}
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNodeAboutUs(
      filter: { langcode: { eq: $language }, status: { eq: true } }
    ) {
      nodes {
        body {
          processed
        }
        title
        drupal_internal__nid
        internal {
          type
        }
        path {
          alias
        }
        field_meta_description
        field_meta_title
        relationships {
          field_components {
            ... on Node {
              ...carouselFragment
              ...imageTextFragment
              ...videoFragment
            }
          }
        }
      }
    }
  }

  fragment carouselFragment on paragraph__carousel {
    field_title
    internal {
      type
    }
    relationships {
      field_items {
        internal {
          type
        }
        field_short_description
        field_title
        relationships {
          field_image {
            ...imageFragment
          }
        }
      }
    }
  }
`
